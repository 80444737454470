<template>
  <div 
    class="checkout-btn-container"
  >
    <AdsButton
      :compact="true"
      :disabled="checkoutDisabled"
      buttonText="Check Out"
      @click="checkout"
    ></AdsButton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: 'CheckoutBtn',
  components: {
    AdsButton,
  },
  props: {
    checkoutDisabled: Boolean,
  },
  methods: {
    checkout() {
      // untick the selected records
      this.$emit('actionFired', 'checkOut');

      this.checkOutVisitors({
        venueId: this.selectedVenueId, 
        events: this.selectedEvents,
      });
    },

    ...mapActions('visitors', [
        'checkOutVisitors',
    ]),
  },
  computed: {
    ...mapGetters('visitors', [
        'selectedEvents',
        'selectedVenueId',
      ]),
  }
}
</script>

<style>
</style>