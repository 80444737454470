<template>
   <span class="text-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="material-icons-outlined icon-color"
          aria-label="View visitor details"
          v-bind="attrs"
          v-on="on"
          :id="record.event_id"
        >
          visibility
        </v-icon>
      </template>

      <v-card class="modal-card">
        <v-toolbar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ record['visitor.name'] }}</v-toolbar-title>
        </v-toolbar>

        <div class="modal-content">
          <v-card-title>
            <div class="playlist-icon-circle">
              <span class="material-icons-outlined playlist-icon">
                playlist_add_check
              </span>
            </div>
            School visitor check-in: {{ record['visitor.name'] }}
          </v-card-title>

          <v-card-text>
            <v-list
              three-line
              subheader
            >
              <div class="record-list">
                <v-list-item class="d-flex list-name list-two-columns">
                  <v-list-item-content>
                    <v-list-item-subtitle>First name</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ getFirstName() }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle>Last name</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ getLastName() }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="d-flex list-phone list-two-columns">
                  <v-list-item-content>
                    <v-list-item-subtitle>Phone no</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['visitor.phone'] }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>Organisation</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['visitor.company_name'] }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="d-flex list-phone list-two-columns">
                  <v-list-item-content>
                    <v-list-item-subtitle>Visitor type</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['visitor.type'] }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>School contact</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['venue_contact.name'] }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="d-flex list-time list-two-columns">
                  <v-list-item-content>
                    <v-list-item-subtitle>Check-in</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ getCheckinTime() }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle>Check-out</v-list-item-subtitle>
                    <v-list-item-title 
                      class="item-value"
                      :class="getCheckoutTime() === 'Expired' ? 'expired-color': ''"
                    >
                      {{ getCheckoutTime() }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="separator-transform"></v-divider>
                <v-list-item class="d-flex list-purpose list-two-columns">
                  <v-list-item-content>
                    <v-list-item-subtitle>Induction</v-list-item-subtitle>
                    <v-list-item-title class="item-value">
                      {{ (record['visitor.site_safety_induction'] === 'true' || record['visitor.site_safety_induction'] === true) ? "Completed" : "Not completed" }}
                    </v-list-item-title>
                  </v-list-item-content>


                  <v-list-item-content>
                    <v-list-item-subtitle>Reason for visit</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['purpose'] }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle>Job ID</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ record['visitor.job_id'] }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="separator-transform"></v-divider>

                <!-- <v-list-item class="d-flex list-q1">
                  <v-list-item-content>
                    <v-list-item-subtitle>Vaccinated?</v-list-item-subtitle>
                    <v-list-item-title class="item-value">
                      <v-icon 
                        :class="[`material-icons-${record.doe_entry_requirements_obj.iconStyle}`, 'entry-requirements-icon']"
                        :color="`${record.doe_entry_requirements_obj.iconColor}`"
                      >
                        {{ record.doe_entry_requirements_obj.icon }}
                      </v-icon>
                      <span>{{ record.doe_entry_requirements_obj.value }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item class="d-flex list-q1">
                  <v-list-item-content>
                    <v-list-item-subtitle>Will you be working in child-related work?</v-list-item-subtitle>
                    <v-list-item-title class="item-value">{{ q1Answer }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import dayjs from 'dayjs';
import { getUTCDateObj } from '../../store/helpers';

export default {
  name: 'VisitorView',
  props: {
    record: undefined,
  },
  data: function() {
    return {
      dialog: false,
      q1Answer: undefined,
    }
  },
  mounted() {
    // get responses from record
    this.getResponse();
  },
  methods: {
    getFirstName() {
      return this.record['visitor.name'].split(' ')[0] ;
    },
    getLastName() {
      return this.record['visitor.name'].indexOf(' ') ?
        this.record['visitor.name'].substring(this.record['visitor.name'].indexOf(' ')) :
        '';
    },
    getCheckoutTime() {
      if (!this.record['checkout_timestamp']) {
        return this.record['timeOut'];
      } else {
        return dayjs(getUTCDateObj(this.record['checkout_timestamp'])).format('DD MMM YYYY hh:mma');
      }
    },
    getCheckinTime() {
      return dayjs(getUTCDateObj(this.record['checkin_timestamp'])).format('DD MMM YYYY hh:mma');
    },
    getResponse() {
      if (this.record['additional_details.questions']) {
        for (const elem of this.record['additional_details.questions']) {
          if (elem.description === 'Will you be working in child-related work?') {
            this.q1Answer = elem.response;
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.modal-card {
  background: #E5E5E5;
}

.modal-content {
  width: 1220px;
  margin-left: auto;
  margin-right: auto;
}

.playlist-icon-circle {
  background-color: #C7DCF0;
  width: 54px;
  height: 54px;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 15px;
}

.playlist-icon {
  font-size: 1.8rem;
}

.v-toolbar {
  flex: 0;
}

.item-value {
  font-weight: bold;
}

.record-list {
  padding-left: 80px;
}

.list-two-columns {
  max-width: 600px;
}

.list-three-columns {
  max-width: 880px;
}

.separator-transform {
  transform: scaleX(1.073) translateX(-37px);
}

.expired-color {
  color: #DC5800;
}

.entry-requirements-icon {
  font-size: 28px !important;
}
</style>