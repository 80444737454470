// ------- AUTH  -------
export const LDAP = '/auth/ldap';
// -------  API
export const VISITORS_List = '/list';

export const VISITORS_Checkout = '/checkout';

export const VISITORS_Mark_Entry = '/mark-entry';

export default{
    LDAP,    
    VISITORS_List,
    VISITORS_Checkout,
    VISITORS_Mark_Entry,
}


