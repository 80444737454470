<template>
  <div class="evacuation-list-print">
    <div class="evacuation-list-print-header flex-container">
      <div class="img-wrapper">
        <img src="/img/nsw-ed-logo-sm.png" alt="icon" class="gov-icon">
      </div>

      <div class="header-title">
        <h2>Visitor Evacuation List</h2>
        <h4 class="mt-2">{{ schoolName }}</h4>
      </div>

      <!-- timestamp -->
      <div class="evacuation-list-print-timestamp align-self-end ml-auto">{{ currentTimestamp }}</div>
    </div>

    <v-divider class="divider-header"></v-divider>
    
    <!-- Evacuation list body -->
    <v-container class="evacuation-list-body" fluid>
      <v-row
      class="mb-3 table-header"
      no-gutters
      >
        <v-col
          v-for="n in 6"
          :key="n"
          cols="2"
        >
          {{ headers[n - 1] }}
        </v-col>
      </v-row>

      <v-row
        class="table-item mb-2"
        v-for="item in records"
        :key="item.event_id"
        no-gutters
      >
        <v-col
          cols="2"
        >
        {{ getCheckinDate(item.checkInDate) }}
        </v-col>

        <v-col
          cols="2"
        >
        {{ item['visitor.name'] }}
        </v-col>

        <v-col
          cols="2"
        >
        {{ item['visitor.phone'] }}
        </v-col>

        <v-col
          cols="2"
        >
        {{ item['visitor.company_name'] }}
        </v-col>

        <v-col
          cols="2"
        >
        {{ item['visitor.type'] }}
        </v-col>

        <v-col
          cols="2"
        >
        {{ item['timeIn'] }}
        </v-col>
      </v-row>

    </v-container>

    <v-divider class="divider-footer"></v-divider>

    <div class="evacuation-list-print-footer">
      <b>Evacuation List</b>
      |
      <span>{{ schoolName }}</span>

      <!-- TODO - pagination -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'evacuation-list-print',
  data: function() {
    return {
      records: JSON.parse(this.$attrs.records) || [],
      schoolName: this.$attrs.schoolName,
      currentTimestamp: dayjs().format('DD/MM/YYYY h:mma'),
      headers: ['Date', 'Name', 'Phone', 'Organization', 'Visitor type', 'In'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      // hide header and banner, fix format
      const header = document.getElementById('appHeader');
      const banner = document.getElementById('appBanner');
      const mobileSchoolMenu = document.getElementById('schoolSelectMobile');
      const mainContainer = document.getElementsByClassName('v-main');
      const appContainer = document.getElementById('app');

      if (header) {
        header.style.display = 'none';
      }

      if (banner) {
        banner.style.display = 'none';
      }

      if (mobileSchoolMenu) {
        mobileSchoolMenu.style.display = 'none';
      }

      if (mainContainer[0]) {
        mainContainer[0].style.padding = '0px';
      }

      if (appContainer) {
        appContainer.style.background = 'none';
      }

      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    });
  },
  methods: {
    getCheckinDate(timestamp) {
      return dayjs(timestamp).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>
@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.flex-container {
  display: flex;
  margin-top: 30px;
}

.gov-icon{
  width: 53px;
  height: 58px;
  margin: 5px 15px 0 15px;
}

.evacuation-list-print-timestamp {
  font-weight: bold;
}

.divider-header {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1.5px solid black;
}

.divider-footer {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: black;
}

.table-header {
  background: #F4F4F7;
  height: 40px;
  align-content: center;
  font-weight: bold;
}
</style>