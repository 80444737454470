import baseService from "./base.service";
//import { localStorageService } from "./local.storage.service";

export const datacontext = {
  get,
  post
};

function get(url, headers, params = null) {
  return baseService
    .get(url, { params, headers })
    .then(response => {
      return response.status == 200 && response.data
        ? response.data
        : () => {
          Promise.reject(response);
        };
    });
}

function post(url, data, headers) {
  return baseService
    .post(url, data, { headers })
    .then(response => {
      return response
    });
}