<template>
  <div 
    class="help-btn-container"
    id="helpButton"
  >
    <AdsButton
      class="help-btn"
      :hidden="hideHelpBtn"
      depressed
      v-if="!isMobileView()"
      @click="openHelpPage()"
    >
      <span class="material-icons-outlined">
        help_outline
      </span>
      <span class="pl-1">Help</span>
    </AdsButton>
  </div>
</template>

<script>
import { AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: 'HelpBtn',
  components: {
    AdsButton,
  },
  props: {
    hideHelpBtn: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    isMobileView() {
      return window.innerWidth < 960;
    },
    openHelpPage() {
      window.open(
        'https://portal.education.nsw.gov.au/portaldata/visitor-checkin/Tech_QRG.pdf',
        '_blank'
      );
    },
  },
}
</script>

<style>
</style>