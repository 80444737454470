<template>
  <div 
    class="evacuation-list-print-btn-container"
    :hidden="!visible"
  >
    <AdsButton
      :tertiary="true"
      :compact="true"
      buttonText="Print List"
      icon="print"
      @click="printList"
    ></AdsButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: 'EvacuationListPrintBtn',
  components: {
    AdsButton,
  },
  props: {
    records: undefined,
    schoolName: undefined,
  },
  data: function() {
    return {
      visible: false,
    };
  },
  watch: {
    evacuationMode() {
      if (window.innerWidth < 600) {
        this.visible = false;
      } else {
        if (this.evacuationMode) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    }
  },
  methods: {
    printList() {
      const routeData = this.$router.resolve({
        name: 'evacuation-list-print', 
      });

      // store data
      sessionStorage.setItem('records', JSON.stringify(this.records));

      sessionStorage.setItem('schoolName', this.schoolName);

      window.open(routeData.href, '_blank');
    }
  },
  computed: {
    ...mapState('visitors', [
        'evacuationMode',
    ]),
  }
}
</script>

<style>
#evacuationListPrint button .v-btn__content i {
    color: #041e42;
}
</style>