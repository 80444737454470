<template>
  <div class="print" >
    <div
      class="flex-container pagebreak"
      v-for='visitor in selectedRecords' 
      :key="visitor.event_id"
    >
      <div class="img-wrapper">
        <img src="/img/nsw-ed-logo-sm.png" alt="icon" class="gov-icon">
      </div>
      
      <div class="visitor-info">
        <h4>Visitor</h4>
        <h5>{{ visitor['visitor.name'] }}</h5>
        <small>{{ getCheckinTime(visitor['checkin_timestamp']) }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'print',
  data: function() {
    return {
      selectedRecords: JSON.parse(this.$attrs.selectedRecords) || [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      // hide header and banner, fix format
      const header = document.getElementById('appHeader');
      const banner = document.getElementById('appBanner');
      const mobileSchoolMenu = document.getElementById('schoolSelectMobile');
      const mainContainer = document.getElementsByClassName('v-main');

      if (header) {
        header.style.display = 'none';
      }

      if (banner) {
        banner.style.display = 'none';
      }

      if (mobileSchoolMenu) {
        mobileSchoolMenu.style.display = 'none';
      }

      if (mainContainer[0]) {
        mainContainer[0].style.padding = '0px';
      }

      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    });
  },
  methods: {
    getCheckinTime(timestamp) {
      const checkInDate = new Date(dayjs(timestamp.slice(0, -3) + 'Z').toDate());

      return dayjs(checkInDate).format('ddd DD MMM YYYY hh:mm A');
    }
  }
};
</script>

<style scoped>
@media print {
  .pagebreak {
        clear: both;
        page-break-after: always;
    }

  .flex-container {
    margin-top: 12%;
    margin-bottom: -10%;
  }

  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.flex-container {
  display: flex;
}

.gov-icon{
  width: 53px;
  height: 58px;
  margin: 5px 15px 0 15px;
}
</style>