<template>
  <div>
    <VisitorsList />
  </div>
</template>

<script>
// @ is an alias to /src
import VisitorsList from "@/components/VisitorsList.vue";

export default {
  name: "home",
  components: {
    VisitorsList,
  },
};
</script>
