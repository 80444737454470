import Vue from 'vue'
import Vuex from 'vuex'
import schools from "./modules/schools"
import visitors from "./modules/visitors"

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,

  modules: {
      schools,
      visitors
  }
})
