import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Print from '../views/Print.vue';
import Unauthorized from '../views/Unauthorized.vue';
import EvacuationListPrint from '../views/EvacuationListPrint.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      protected: true,
    },    
  },
  {
    path: '/print',
    name: 'print',
    component: Print,
    props: { 
      selectedRecords: sessionStorage.getItem('selectedRecords'),
    },
    meta: {
      protected: true,
    },    
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    meta: {
      protected: true,
    },
  },
  {
    path: '/evacuation-list-print',
    name: 'evacuation-list-print',
    component: EvacuationListPrint,
    props: {
      records: sessionStorage.getItem('records') || [],
      schoolName: sessionStorage.getItem('schoolName') || '',
    },
    meta: {
      protected: true,
    },    
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
export default router;
